import axios from 'axios';
import { getAccessToken } from './Auth';

export const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/swex' : 'https://dynamic.swop.one/swex';
axios.defaults.baseURL = baseURL;

export function requestApi(endpoint, method, query, data) {
  const options = {};
  if (method) {
    options.method = method;
  }
  if (query) {
    options.params = query;
  }
  if (data) {
    options.data = data;
  }
  options.headers = { Authorization: `Bearer ${getAccessToken()}` };

  return axios(endpoint, options);
}
