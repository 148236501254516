import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { Button, Form, Header, Icon, Modal } from 'semantic-ui-react';

class Settings extends Component {
  state = { settings: this.props.settings };

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.settings, prevProps.settings)) {
      this.setState({ settings: this.props.settings });
    }
  }

  handleChange = (e, { name, value }) => this.setState({ settings: { [name]: value } });

  render() {
    return (
      <Modal open={this.props.open} size="small">
        <Header icon="settings" content="Settings" subheader="Handle all SWEX relevant settings." />
        <Modal.Content>
          <Form>
            <Form.Input
              label="Swarfarm API Key"
              placeholder="Swarfarm API Key"
              name="swarfarmAPIKey"
              value={this.state.settings.swarfarmAPIKey}
              onChange={this.handleChange}
            />
            <p>
              You can get the API key on the{' '}
              <a href="https://swarfarm.com/profile/Xzandro/edit/" target="_blank" rel="noopener noreferrer">
                SWARFARM Settings
              </a>{' '}
              page. See also porksmash's{' '}
              <a href="https://www.youtube.com/watch?v=7i0RT9IkHjs" target="_blank" rel="noopener noreferrer">
                video
              </a>
              .
            </p>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={() => {
              this.props.saveSettings(this.state.settings);
            }}
            inverted
          >
            <Icon name="checkmark" /> Save
          </Button>
          <Button
            color="blue"
            onClick={() => {
              this.props.toggleModal();
            }}
            inverted
          >
            <Icon name="close" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Settings;
