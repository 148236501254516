import React, { Component } from 'react';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import { Container, Image, Segment } from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import logo from './logo.png';
import './App.css';

import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Callback from './pages/Callback';

class App extends Component {
  constructor() {
    super();
    this.state = {
      cookieAccepted: true
    };
  }

  componentDidMount() {
    window.addEventListener('newContentAvailable', () => {
      this.notify('Update available! Please refresh this page.', { type: 'info', autoClose: false });
    });
  }

  notify = (message, options) => {
    const mergedOptions = Object.assign({ position: 'bottom-right' }, options);
    toast(message, mergedOptions);
  };

  componentDidMount = () => {
    const cookieAccepted = localStorage.getItem('cookieAccepted') ? true : false;
    this.setState({
      cookieAccepted
    });
  };

  cookieAccept = () => {
    localStorage.setItem('cookieAccepted', true);
    document.querySelector('#cookiebar').remove();
  };

  render() {
    return (
      <div className="App">
        <ToastContainer />
        <header className="App-header">
          <Container>
            <Image centered src={logo} size="tiny" as={Link} to="/" />
            <h1 className="App-title">Welcome to SWEX Web</h1>
          </Container>
        </header>
        <Switch>
          <Route exact path="/" render={props => <Home {...props} notify={this.notify.bind(this)} />} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/callback" component={Callback} />
        </Switch>
        <footer>
          <Container>
            <Segment basic textAlign={'right'}>
              <Link to="/privacy">ToS / Privacy</Link> - © Xzandro {new Date().getFullYear()}
            </Segment>
          </Container>
        </footer>
        {!this.state.cookieAccepted ? (
          <div id="cookiebar" className="bottom" role="complementary" aria-describedby="cookiebar-desc">
            <div id="cookiebar-desc" aria-live="assertive" role="alert">
              <span>This site uses cookies to ensure the best experience. Using this site means you agree to our use of cookies.</span>
              <Link to="/privacy">Read...</Link>
            </div>
            <button id="cookiebar-accept" onClick={() => this.cookieAccept()}>
              Okay!
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(App);
