import { Component } from 'react';

import { auth, setIdToken, setAccessToken, setExpiresAt } from '../Auth';

class Callback extends Component {

  componentDidMount() {
    auth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken && authResult.expiresIn) {
        // Store the authResult in local storage and redirect the user elsewhere
        setAccessToken(authResult.accessToken);
        setIdToken(authResult.idToken);
        setExpiresAt(authResult.expiresIn);
      } else if (err) {
        console.error(err);
      }
      window.location.href = "/";
    });

  }

  render() {
    return null;
  }
}

export default Callback;