import React, { Component } from 'react';
import moment from 'moment';
import { requestApi } from '../Api';
import { Button, Icon, Table, Popup } from 'semantic-ui-react';
import { getAccessToken } from '../Auth';
import { baseURL } from '../Api';

class Files extends Component {
  state = { buttonLoading: {} };

  downloadFile = (e, element) => {
    const fileName = element['data-file'];
    const fileApp = element['data-app'];
    const fileType = element['data-type'];
    window.location.href = `${baseURL}/download?file=${fileName}&app=${fileApp}&type=${fileType}&access_token=${getAccessToken()}`;
  };

  uploadToSwarfarm = (e, element) => {
    this.setState({ buttonLoading: { [element['data-file']]: true } });
    const data = { app: element['data-app'], type: element['data-type'], file: element['data-file'] };
    requestApi('/swarfarm', 'post', null, data)
      .then(res => {
        console.log(res.data);
        if (res.data.code !== 200) {
          this.props.notify(res.data.message, { type: 'error' });
        } else {
          this.props.notify(res.data.message, { type: 'success' });
        }
        this.setState({ buttonLoading: { [element['data-file']]: false } });
      })
      .catch(e => {
        this.setState({ buttonLoading: { [element['data-file']]: false } });
      });
  };

  render() {
    const filesEntries = this.props.files
      ? this.props.files.map(file => {
          return (
            <Table.Row key={file.name}>
              <Table.Cell>
                <Icon name="file outline" />
                {file.fullName}
              </Table.Cell>
              <Table.Cell>{moment(file.created).fromNow()}</Table.Cell>
              <Table.Cell>{(file.size / 1000000).toFixed(2)} MB</Table.Cell>
              <Table.Cell textAlign="right">
                <Button primary data-file={file.name} data-app={file.app} data-type={file.type} onClick={this.downloadFile}>
                  Download
                </Button>
                {this.props.swarfarm ? (
                  <Popup
                    trigger={
                      <Button
                        primary
                        loading={this.state.buttonLoading[file.name]}
                        data-file={file.name}
                        data-app={file.app}
                        data-type={file.type}
                        onClick={this.uploadToSwarfarm.bind(this)}
                      >
                        Swarfarm
                      </Button>
                    }
                    content="Upload to SWARFARM. Requires you to set the API key in the Settings."
                    hideOnScroll
                  />
                ) : (
                  ''
                )}
                <Button icon="delete" data-file={file.name} data-app={file.app} data-type={file.type} onClick={this.props.deleteFiles} />
              </Table.Cell>
            </Table.Row>
          );
        })
      : '';
    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">{this.props.headerTitle ? this.props.headerTitle : 'Files'}</Table.HeaderCell>
            <Table.HeaderCell colSpan="1">Size</Table.HeaderCell>
            <Table.HeaderCell colSpan="1" textAlign="right">
              <Button icon="refresh" onClick={this.props.refreshFiles} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{filesEntries}</Table.Body>
      </Table>
    );
  }
}

export default Files;
