import React, { Component } from 'react';
import moment from 'moment';
import logo from '../logo.png';
import { Accordion, Button, Card, Container, Grid, Header, Icon, Image, List, Message, Popup, Segment } from 'semantic-ui-react';
import { login, logout, getProfile } from '../Auth';
import { requestApi } from '../Api';
import Files from '../components/Files';
import Settings from '../components/Settings';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      profile: null,
      files: null,
      activeIndex: 0,
      minPledgeAmount: 0,
      timeRemaining: 0,
      ip: null,
      unlockAllowed: false,
      unlockButtonDisabled: true,
      unlockButtonText: 'Unlock',
      declinedSince: null,
      settingsModal: false,
      settings: {}
    };
  }

  timeLeftInterval = null;

  componentDidMount() {
    getProfile((err, profile) => {
      if (profile) {
        this.setState({
          profile
        });
        requestApi('/status').then(res => {
          this.setState({
            minPledgeAmount: res.data.minPledgeAmount,
            declinedSince: res.data.declinedSince
          });

          if (res.data.timeRemaining > 0) {
            this.setState({
              unlockAllowed: res.data.unlockAllowed,
              ip: res.data.ip
            });
            this.timeRemaining(res.data.timeRemaining);
          } else {
            this.setState({
              unlockButtonDisabled: !res.data.unlockAllowed,
              unlockAllowed: res.data.unlockAllowed
            });
          }
        });
        requestApi('/settings').then(res => {
          this.setState({ settings: res.data.settings });
        });
        requestApi('/fileinfo').then(res => {
          this.setState({
            files: res.data
          });
        });
      }
    });
  }

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  unlockProxy = () => {
    requestApi('/unlock').then(res => {
      this.setState({ unlockAllowed: res.data.unlockAllowed, ip: res.data.ip });
      if (res.data.timeRemaining > 0) {
        this.timeRemaining(res.data.timeRemaining);
      }
    });
  };

  cancelUnlock = () => {
    requestApi('/unlock', 'delete').then(res => {
      clearInterval(this.timeLeftInterval);
      this.setState({
        unlockButtonDisabled: !this.state.unlockAllowed,
        unlockButtonText: 'Unlock',
        unlockAllowed: res.data.unlockAllowed,
        timeRemaining: res.data.timeRemaining,
        ip: null
      });
    });
  };

  timeRemaining = timeLeft => {
    let remaining = timeLeft;
    this.timeLeftInterval = setInterval(() => {
      this.setState({
        unlockButtonDisabled: true,
        unlockButtonText: `${moment.duration(remaining, 'seconds').humanize()} remaining`,
        timeRemaining: remaining
      });
      remaining = remaining - 1;
      if (remaining <= 0) {
        this.setState({ unlockButtonDisabled: !this.state.unlockAllowed, unlockButtonText: 'Unlock', timeRemaining: 0 });
        clearInterval(this.timeLeftInterval);
      }
    }, 1000);
  };

  refreshFiles = (e, element) => {
    requestApi('/fileinfo').then(res => {
      this.props.notify('Files refreshed.', { type: 'success' });
      this.setState({
        files: res.data
      });
    });
  };

  deleteFiles = (e, element) => {
    const data = {
      app: element['data-app'],
      type: element['data-type'],
      file: element['data-file']
    };
    requestApi('/files', 'delete', null, data).then(res => {
      this.props.notify('File deleted.', { type: 'success' });
      this.setState({
        files: res.data
      });
    });
  };

  toggleSettingsModal = () => {
    this.setState({ settingsModal: !this.state.settingsModal });
  };

  saveSettings = settings => {
    requestApi('/settings', 'post', null, settings).then(res => {
      this.setState({ settings: settings });
      this.toggleSettingsModal();
      this.props.notify('Settings saved.', { type: 'success' });
    });
  };

  getCert = () => {
    requestApi('/cert').then(res => {
      const data = JSON.stringify(res.data)
        .replace(/\\r/g, '\r')
        .replace(/\\n/g, '\n')
        .replace(/"/g, '');
      const blob = new Blob([data], {
        type: 'application/x-x509-ca-cert'
      });
      const e = document.createEvent('MouseEvents'),
        a = document.createElement('a');
      a.download = 'ca.pem';
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['application/x-x509-ca-cert', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    });
  };

  render() {
    const {
      activeIndex,
      declinedSince,
      profile,
      files,
      unlockAllowed,
      unlockButtonText,
      unlockButtonDisabled,
      settings,
      settingsModal,
      timeRemaining,
      ip
    } = this.state;
    return (
      <div>
        <Container>
          <Segment basic>
            {declinedSince ? (
              <Message size="small" color="yellow">
                It seems like your payment got declined on{' '}
                <a href="https://www.patreon.com/" target="_blank" rel="noopener noreferrer">
                  Patreon
                </a>
                . Until you resolve this issue, you won't be able to Unlock the SWEX Web proxy. If you have already resolved this issue you might have
                to logout and login again. Thank you for your understanding.​
              </Message>
            ) : null}
            <Message
              icon="question circle"
              header="What is this?"
              content="SWEX is a tool that parses intercepted data from Summoner's War and extract information on the monsters and runes of the user. The only downside was, you needed to download an extra app to do it. This project tries to solve this."
            />
            <Settings
              open={settingsModal}
              toggleModal={this.toggleSettingsModal.bind(this)}
              saveSettings={this.saveSettings.bind(this)}
              settings={settings}
            />
            <Grid>
              <Grid.Column mobile={16} computer={10}>
                <Accordion fluid styled>
                  <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordionClick}>
                    <Icon name="dropdown" />
                    How does it work?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0}>
                    <p>
                      It's quite straight forward. You first log in via your{' '}
                      <a href="https://www.patreon.com/" target="_blank" rel="noopener noreferrer">
                        Patreon
                      </a>{' '}
                      account. After that you can unlock the proxy via the Unlock button. You now have 5 minutes to setup the proxy and connect to
                      Summoner's War.
                    </p>
                    <p>
                      <strong>Important:</strong> since this system is IP based you need to hit the Unlock button via the same device you log into
                      Summoners War!
                    </p>
                    <p>
                      Hostname / IP: <strong>{profile ? 'proxy.swop.one' : 'Login to see.'}</strong>
                      <br />
                      Port: <strong>{profile ? '3003' : 'Login to see.'}</strong>
                    </p>
                  </Accordion.Content>
                  <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleAccordionClick}>
                    <Icon name="dropdown" />
                    Do I have to be a patron?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 1}>
                    <p>
                      Yes and no. This is an early beta test, where I test the basic functionality. Within the test phase I will require a minimum
                      pledge amount of $3. You also can contact me via{' '}
                      <a href="https://www.reddit.com/user/Xzandro" target="_blank" rel="noopener noreferrer">
                        reddit
                      </a>{' '}
                      or{' '}
                      <a href="https://discordapp.com/" target="_blank" rel="noopener noreferrer">
                        Discord
                      </a>{' '}
                      (Xzandro#0001) so I can unlock you if you want to try it out first.
                    </p>
                    <p>
                      I don't mind giving people temporary access if they REALLY need it, but this tool is for my patrons primarily. In any case you
                      do need a Patreon account though.
                      <br />
                      <strong>After you have unlocked the features it can take up to 4 hours to take effect. Or just logout and login again.</strong>
                    </p>
                    <p>
                      If you pledge $5+ you also gain access to the{' '}
                      <a href="https://tool.swop.one/" target="_blank" rel="noopener noreferrer">
                        SWOP
                      </a>{' '}
                      &lt;-&gt; SWEX Web sync / import.
                    </p>
                    <p>
                      You can find my Patreon here:{' '}
                      <a href="https://www.patreon.com/Xzandro" target="_blank" rel="noopener noreferrer">
                        patreon.com/Xzandro
                      </a>{' '}
                      if you want to support me.
                    </p>
                  </Accordion.Content>
                  <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleAccordionClick}>
                    <Icon name="dropdown" />
                    Is this a scam? What happens with my data?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 3}>
                    <p>
                      This is not a scam. This site doesn't save any user related data. Login happens via{' '}
                      <a href="https://auth0.com/privacy" target="_blank" rel="noopener noreferrer">
                        auth0
                      </a>{' '}
                      and Patreon's{' '}
                      <a href="https://www.patreon.com/portal/start/oauth-explained" target="_blank" rel="noopener noreferrer">
                        OAuth
                      </a>
                      . So no sensitive data involved. We only save your IP temporarely to make sure that only authorized users can use this proxy.
                    </p>
                    <p>
                      I'm also the devoloper of various other tools for Summoner's War, like{' '}
                      <a href="https://tool.swop.one/" target="_blank" rel="noopener noreferrer">
                        SWOP
                      </a>{' '}
                      and{' '}
                      <a href="https://gw.swop.one/" target="_blank" rel="noopener noreferrer">
                        SWAG
                      </a>
                      .
                    </p>
                  </Accordion.Content>
                  <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleAccordionClick}>
                    <Icon name="dropdown" />
                    Still no idea how this all works? Quickstart!
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 4}>
                    <p>
                      Go to the SWEX Web page (if you read this, you are already there) (don't connect to the proxy yet). Unlock the Proxy via your
                      Phone (Unlock button on the SWEX Web page). Now you have 5, 10 or more (depending on your Patreon pledge) minutes to set the
                      proxy to your Wifi settings. (Hostname / Server: {profile ? 'proxy.swop.one' : 'Login to see.'} Port:{' '}
                      {profile ? '3003' : 'Login to see.'}) If you did that you can now log into Summoners War and after that, your file will apear on
                      the bottom of this page.
                    </p>
                    <p>
                      If you don't know how to set a proxy to your Wifi or APN settings of your smartphones, there are additional information in the
                      Caveats / Troubleshoot section.
                    </p>
                  </Accordion.Content>
                  <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleAccordionClick}>
                    <Icon name="dropdown" />
                    Troubleshoot / Caveats
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 2}>
                    <List>
                      <List.Item>
                        <List.Header>I can't log into SW after I set the Proxy!</List.Header>
                        Please make sure you Unlocked the proxy with the same device you want to login in SW. It's important, since this system is IP
                        based. Also, please double check, that you entered the correct Port and Server.
                      </List.Item>
                      <List.Item>
                        <List.Header>I can't download the file on iOS!</List.Header>
                        Sadly, this is a limitations on iOS, I don't have access to the file system. You might have to save the file to DropBox or
                        other Cloud services and select the files from there.
                      </List.Item>
                      <List.Item>
                        <List.Header>I cant input the proxy data to my mobile data!</List.Header>
                        Some mobile data provider don't allow changing the APN settings. Nothing you can do, besides finding a Wifi network and
                        connect to it. You could also try to set the proxy via apps like{' '}
                        <a href="https://play.google.com/store/apps/details?id=org.proxydroid" target="_blank" rel="noopener noreferrer">
                          ProxyDroid
                        </a>
                        . I heard great things about them.
                      </List.Item>
                      <List.Item>
                        <List.Header>Can you give instructions on how to set the proxy in the Wifi and Mobile data settings?</List.Header>
                        Sure! Android:{' '}
                        <a
                          href="https://www.howtogeek.com/295048/how-to-configure-a-proxy-server-on-android/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Wifi
                        </a>{' '}
                        |{' '}
                        <a
                          href="https://sebastian.expert/changing-proxy-settings-for-3g-mobile-data-in-android-without-root/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Mobile
                        </a>
                        . iOS:{' '}
                        <a
                          href="https://www.howtogeek.com/293676/how-to-configure-a-proxy-server-on-an-iphone-or-ipad/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Wifi
                        </a>{' '}
                        |{' '}
                        <a
                          href="https://apple.stackexchange.com/questions/81102/proxy-settings-for-iphone-3g-connection"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Mobile
                        </a>
                      </List.Item>
                    </List>
                  </Accordion.Content>
                </Accordion>
              </Grid.Column>
              <Grid.Column mobile={16} computer={6}>
                <Card fluid>
                  <Image centered size={'small'} src={profile ? profile.picture : logo} />
                  {profile ? (
                    <Card.Content>
                      <Card.Header>{profile.name}</Card.Header>
                      <Card.Meta>{profile.email}</Card.Meta>
                      {timeRemaining > 0 ? (
                        <Popup
                          trigger={
                            <Button
                              content="Cancel"
                              icon="delete"
                              label={{ as: 'a', basic: true, pointing: 'right', content: ip }}
                              labelPosition="left"
                              style={{ marginTop: '5px' }}
                              onClick={this.cancelUnlock}
                            />
                          }
                          content="You unlocked the Proxy with this IP. Do not use this IP for your Proxy settings! This is just an information."
                        />
                      ) : null}
                      {ip === 'proxy' ? (
                        <Message size="tiny" color="yellow">
                          You are connected to the Proxy so it is not possible to get your real IP. Please remove the Proxy from your smartphone
                          settings and try unlocking again and read the steps in the FAQ.
                        </Message>
                      ) : null}
                    </Card.Content>
                  ) : null}
                  <Card.Content extra>
                    {profile ? (
                      <Button.Group fluid>
                        {!unlockAllowed && timeRemaining <= 0 ? (
                          <Popup
                            hoverable
                            flowing
                            trigger={
                              <div style={{ flex: '1 0 auto' }}>
                                <Button
                                  fluid
                                  basic
                                  disabled={unlockButtonDisabled}
                                  content={unlockButtonText}
                                  color="blue"
                                  onClick={() => this.unlockProxy()}
                                />
                              </div>
                            }
                          >
                            <Grid centered divided columns={2}>
                              <Grid.Column textAlign="center">
                                <Header as="h4">Simple Access</Header>
                                <p>$3 pledge on Patreon</p>
                                <Button
                                  onClick={() => {
                                    window.open('https://www.patreon.com/bePatron?c=1511791&rid=2537640', '_blank');
                                  }}
                                >
                                  Choose
                                </Button>
                              </Grid.Column>
                              <Grid.Column textAlign="center">
                                <Header as="h4">Basic Access</Header>
                                <p>$5 pledge on Patreon</p>
                                <Button
                                  onClick={() => {
                                    window.open('https://www.patreon.com/bePatron?c=1511791&rid=2407532', '_blank');
                                  }}
                                >
                                  Choose
                                </Button>
                              </Grid.Column>
                            </Grid>
                          </Popup>
                        ) : (
                          <Button basic disabled={unlockButtonDisabled} content={unlockButtonText} color="blue" onClick={() => this.unlockProxy()} />
                        )}
                        <Button basic color="blue" onClick={() => this.getCert()}>
                          Cert
                        </Button>
                        <Button basic color="blue" onClick={() => this.toggleSettingsModal()}>
                          Settings
                        </Button>
                        <Button basic color="red" onClick={() => logout()}>
                          Logout
                        </Button>
                      </Button.Group>
                    ) : (
                      <Button.Group fluid>
                        <Button basic color="green" onClick={() => login()}>
                          Login
                        </Button>
                      </Button.Group>
                    )}
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid>
          </Segment>
          {profile && files ? (
            <div>
              <Files
                files={files.proxy}
                settings={settings}
                swarfarm={true}
                deleteFiles={this.deleteFiles}
                refreshFiles={this.refreshFiles}
                headerTitle={`SWEX Proxy Files (${files.proxy.length}/3)`}
                notify={this.props.notify}
              />
              <Files
                files={files.swop.data}
                deleteFiles={this.deleteFiles}
                refreshFiles={this.refreshFiles}
                headerTitle={`SWOP Data Files (${files.swop.data.length}/3)`}
                notify={this.props.notify}
              />
              <Files
                files={files.swop.settings}
                deleteFiles={this.deleteFiles}
                refreshFiles={this.refreshFiles}
                headerTitle={`SWOP Settings File (${files.swop.settings.length}/3)`}
                notify={this.props.notify}
              />
            </div>
          ) : null}
        </Container>
      </div>
    );
  }
}

export default Home;
