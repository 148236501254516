import auth0 from 'auth0-js';
const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';
const EXPIRES_AT_KEY = 'expires_at';

const CLIENT_ID = 'To0JYGZjrcyz3ZWmezlTfkqaZkio4yIu';
const CLIENT_DOMAIN = 'xzandro.eu.auth0.com';
const SITE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://swex.swop.one/';
const REDIRECT = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/callback' : 'https://swex.swop.one/callback';
const SCOPE = 'openid profile email';
const AUDIENCE = 'https://swex.swop.one/';

let silentAuthTimeout = null;

export const auth = new auth0.WebAuth({
  clientID: CLIENT_ID,
  domain: CLIENT_DOMAIN
});

export const tokenNamespace = 'https://swex.swop.one/';

export function login() {
  auth.authorize({
    responseType: 'token id_token',
    redirectUri: REDIRECT,
    audience: AUDIENCE,
    scope: SCOPE
  });
}

export function logout(noRedirect) {
  clearAccessToken();
  clearIdToken();
  clearExpiresAt();
  clearOldNonces();

  clearTimeout(silentAuthTimeout);

  if (!noRedirect) {
    auth.logout({ clientID: CLIENT_ID, returnTo: SITE_URL});
  }
  
}

export function getExpiresAt() {
  return parseInt(localStorage.getItem(EXPIRES_AT_KEY), 10);
}

export function getIdToken() {
  return localStorage.getItem(ID_TOKEN_KEY);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getProfile(cb) {
  if (!isLoggedIn()) {
    if (!localStorage.getItem(EXPIRES_AT_KEY)) {
      if(cb) { cb(null, null) };
      return;
    }
    // try silent authentication if not loggedIn
    console.log('Token renewal');
    renewToken(cb);
  } else {
    if (cb) { 
      const accessToken = getAccessToken();
      auth.client.userInfo(accessToken, (err, profile) => {
        cb(err, profile);
      });
    }
    console.log('Logged in, setting renewal timeout');
    setRenewalTimeout();
  }
}

function clearIdToken() {
  localStorage.removeItem(ID_TOKEN_KEY);
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}
function clearExpiresAt() {
  localStorage.removeItem(EXPIRES_AT_KEY);
}

function clearOldNonces() {
  Object.keys(localStorage).forEach(key => {
    if (!key.startsWith('com.auth0.auth')) return;
    localStorage.removeItem(key);
  });
}

function renewToken(cb) {
  auth.checkSession({
    responseType: 'token id_token',
    audience: AUDIENCE,
    scope: SCOPE,
    redirectUri: REDIRECT
  }, (err, authResult) => {
    if (err) {
      logout(true);
    } else {
      setAccessToken(authResult.accessToken);
      setIdToken(authResult.idToken);
      setExpiresAt(authResult.expiresIn);
      setRenewalTimeout();
      if (cb) {
        auth.client.userInfo(authResult.accessToken, (err, profile) => {
          cb(err, profile);
        });
      }
    }
  });
}

function setRenewalTimeout() {
  const expiresAt = getExpiresAt();
  const delay = expiresAt - Date.now();
  if (delay > 0) {
    silentAuthTimeout = setTimeout(() => {
      renewToken();
    }, delay);
  }
}

// Get and store access_token in local storage
export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

// Get and store id_token in local storage
export function setIdToken(token) {
  localStorage.setItem(ID_TOKEN_KEY, token);
}

export function setExpiresAt(time) {
  const expiresAt = JSON.stringify(
    time * 1000 + new Date().getTime()
  );

  localStorage.setItem(EXPIRES_AT_KEY, expiresAt);
}

export function isLoggedIn() {
  const idToken = getIdToken();
  if (!!idToken && !isTokenExpired()) {
    return true
  }

  return false;
}

function isTokenExpired() {
  const expirationDate = getExpiresAt();
  return new Date(expirationDate) < new Date();
}